import './AboutSection.scss';
import man from './man.png';


export default function AboutSection() {
  return(


    <section className="about-section section">
      <div className="container">

        <div className="about-section__row">

          <div className="about-section__content">

            <a className="button button-round" href="#">
              <svg className="button-round__icon" width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5303 6.53033C20.8232 6.23744 20.8232 5.76256 20.5303 5.46967L15.7574 0.696699C15.4645 0.403806 14.9896 0.403806 14.6967 
                0.696699C14.4038 0.989593 14.4038 1.46447 14.6967 1.75736L18.9393 6L14.6967 10.2426C14.4038 10.5355 14.4038 11.0104 14.6967 11.3033C14.9896 11.5962 15.4645 
                11.5962 15.7574 11.3033L20.5303 6.53033ZM0 6.75H20V5.25H0V6.75Z" fill=""/>
              </svg>
            </a>

            <span className="about-section__info">
              Meet your host
            </span>

            <h2 className="about-section__title">
              Jacob Paulaner
            </h2>

            <div className="about-section__wrap-text">
              <p>
                Jacob has a background in audio engineering, and has been podcasting since the early days.
              </p>
              <p>
                He’s here to help you level up your game by sharing everything he’s learned along the way.
              </p>
            </div>


          </div>

          <div className="about-section__wrap-img">
            <img className ='about-section__img' src={man} alt="man" width={650} height={873} />
          </div>

        </div>

      </div>
    </section>

  )
}