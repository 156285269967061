import styles from './CommentItem.module.scss';

import star from './star.svg';





export default function CommentItem({name, text}) {

  return(

    <div className={styles.box}>
      <span className={styles.rating}>
        <img src={star} alt="icon star" />
        <img src={star} alt="icon star" />
        <img src={star} alt="icon star" />
        <img src={star} alt="icon star" />
        <img src={star} alt="icon star" />
      </span>
      <h4 className={styles.text}>
        {text}
      </h4>
      <p className={styles.name}>
        {name}
      </p>
    </div>

  )

}