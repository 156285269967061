import styles from './EpisodeItem.module.scss';




export default function EpisodeItem({handleClick, classAdd = '', text,src,alt,type,id,title}) {

  return(

    <div className={styles.box + ' ' + classAdd}>
      <img className={styles.img} src={src} alt={alt} />
      <div className={styles.content}>
          <span className={styles.badge}>
            {type}
          </span>
          <span className={styles.info}>
            Episode {id}
          </span>
          <div className={styles.title}>
            {title}
          </div>
          <p className={styles.text}>
            {text}
          </p>
          <button onClick={handleClick} className={styles.button + ' ' + 'button'}>
            View Episode Details
          </button>
      </div>
    </div>

  )

}