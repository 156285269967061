import './ModalWindow.scss';

export default function ModalWindow({handleClick, addClass='', title, text}) {

  return (

    <div onClick={handleClick} className={"modal-window" + ' ' + addClass}>
      <div className='modal-window__content'>
        <span className="modal-window__cross cross">
          <i className="cross__line"></i>
        </span>
        <div className="modal-window__title">
          {title}
        </div>
        <p className="modal-window__text">
          {text}
        </p>
      </div>
    </div>

  )

}