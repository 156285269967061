import { useState, useEffect } from 'react';
import CommentItem from '../CommentItem/CommentItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import {  Pagination } from 'swiper/modules';


import './CommentSection.scss';



export default function CommentSection() {

  let [comments, setComments] = useState([]);

  useEffect(() => {
    fetch('https://jsonplaceholder.typicode.com/users/')
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setComments(data)
      })
  }, [])


  return(

    <section className="comment-section section">
      <div className="container">
      
        <Swiper
          modules={[Pagination]}
          className='comment-section__slider'
          spaceBetween={50}
          autoHeight={true}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {
            comments.map( item => <SwiperSlide key={item.id}><CommentItem name={item.name} text={item.company.catchPhrase}/></SwiperSlide> )
          }
        </Swiper>

        <div className="comment-section__items">
          {
            comments.map( item => <CommentItem key={item.id} name={item.name} text={item.company.catchPhrase} /> )
          }
        </div>

      </div>
    </section>

  )
}