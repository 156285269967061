import IntroSection from "../components/IntroSection/IntroSection";
import EpisodeSection from "../components/EpisodeSection/EpisodeSection";
import AboutSection from "../components/AboutSection/AboutSection";
import SubscribeSection  from "../components/SubscribeSection/SubscribeSection";
import CommentSection  from "../components/CommentSection/CommentSection";


export default function Homepage() {
  return(

      <>

        <IntroSection />

        <EpisodeSection />

        <AboutSection />

        <SubscribeSection />

        <CommentSection />

      </>
    
  )
}