import './SubscribeSection.scss';

import {useState} from 'react';


export default function SubscribeSection() {


  // handle NAME
  const [name, setName] = useState({
    value: '',
    hasError: true
  });

  function handleChangeName(event) {

    let valueName = event.target.value;
    let isError: boolean;
    let textErrorName = document.querySelector('.label__text-error_name');
    let labelName = textErrorName.parentElement;

    for (let i = 0; i < valueName.split('').length; i++) {
      if ( !valueName.split('')[i].match(/[а-яёa-z]/gi) || i > 10 ) {
        return isError = false;
      }
    }

    if (valueName.length < 2) {
      isError = true;
    }

    if (valueName.length > 1) {
      labelName.classList.remove('label_error');
      textErrorName.innerHTML = '';
    }

    if (valueName === '') {
      isError = true;
    }
    
    setName({
      value: valueName,
      hasError: isError
    })
    
  }

  function handleBlurName(event) {
    let valuename2 = event.target.value;
    let textErrorName2 = document.querySelector('.label__text-error_name');
    let labelName2 = textErrorName2.parentElement;

    if (valuename2.length < 2) {
      labelName2.classList.add('label_error');
      textErrorName2.innerHTML = 'введите хотя бы 2 символа';
    }

    else {
      labelName2.classList.remove('label_error');
      textErrorName2.innerHTML = '';
    }
  }


  // handle EMAIL
  const [email, setEmail] = useState({
    value: '',
    hasError: true
  });

  function handleChangeEmail(event: any) {

    let valueEmail = event.target.value;
    let isErrorEmail: boolean;
    let textErrorEmail = document.querySelector('.label__text-error_email');
    let labelEmail = textErrorEmail.parentElement;

    const regExpEmail = /^([\w\.\+]{1,})([^\W])(@)([\w]{1,})(\.[\w]{1,})+$/;

    if (regExpEmail.test(valueEmail)) {
      isErrorEmail = false;
      labelEmail.classList.remove('label_error');
      textErrorEmail.innerHTML = '';
    }
    else {
      isErrorEmail = true;
    }

    setEmail({
      value: valueEmail,
      hasError: isErrorEmail
    })
  }

  function handleBlurEamil() {
    let textErrorEmail2 = document.querySelector('.label__text-error_email');
    let labelEmail2 = textErrorEmail2.parentElement;

    if (email.hasError) {
      labelEmail2.classList.add('label_error');
      textErrorEmail2.innerHTML = 'введите корректный адрес';
    }

    else {
      labelEmail2.classList.remove('label_error');
      textErrorEmail2.innerHTML = '';
    }

  }

  // handle SUBMIT
  function handleSubmit(event: any) {

    event.preventDefault();

    const formSubscribe = document.getElementById('form-subscribe') as HTMLFormElement;

    const params = new FormData(formSubscribe) as any;

    event.target.classList.add('form-subscribe_active');

    const url = "https://reqres.in/api/users/";
    fetch(url, {
      method: 'POST',
      body: params,
    })
      .then(res => {
        if (res.ok) {
          console.log(params)

          setTimeout(function() {

            setName({
              value: '',
              hasError: true
            })
            setEmail({
              value: '',
              hasError: true
            })

            event.target.classList.remove('form-subscribe_active');
            document.querySelector('.subscribe-section__title').innerHTML = 'You are subscribed!😀';

          }, 800);

        } else {
          console.log(res)
        }

      })
      .then(data => console.log('data: ', data))
      .catch(function(error) { 
        console.log('error', error)
        setTimeout(function() {

          setName({
            value: '',
            hasError: true
          })
          setEmail({
            value: '',
            hasError: true
          })

          event.target.classList.remove('form-subscribe_active');
          document.querySelector('.subscribe-section__title').innerHTML = 'Sorry, please try again.🙂';

        }, 800);
      });

  }



  return(

    <section className="subscribe-section section">
      <div className="container">

        <div className="subscribe-section__inner">

          <div className="subscribe-section__content">

            <span className="subscribe-section__info">
              Email Newsletter
            </span>

            <h2 className="subscribe-section__title">
              Subscribe<br/> for updates
            </h2>

          </div>

          <form
            id="form-subscribe"
            onSubmit={handleSubmit}
            className="subscribe-section__form form-subscribe"
            noValidate>

            <label className='label'>
                <input
                  onChange={handleChangeName}
                  onBlur={handleBlurName}
                  value={name.value}
                  className="label__input input"
                  type="text"
                  placeholder='Name'
                  name='name'
                />
                <span className="label__text-error label__text-error_name"></span>
            </label>

            <label className='label'>
              <input
                onChange={handleChangeEmail}
                onBlur={handleBlurEamil}
                value={email.value}
                className="label__input input"
                type="email"
                placeholder='Email'
                name='email'
              />
              <span className="label__text-error label__text-error_email"></span>
            </label>
 
            <button 
              className='form-subscribe__button button'
              disabled={name.hasError || email.hasError}
              type='submit'
              >
                Submit
            </button>

          </form>

        </div>

      </div>
    </section>

  )
}