import { useState, useEffect } from 'react';
import TitleBase from '../TitleBase/TitleBase';
import EpisodeItem from '../EpisodeItem/EpisodeItem';
import ModalWindow from '../ModalWindow/ModalWindow';


import {items} from './data';


import './EpisodeSection.scss';


export default function EpisodeSection() {

  const html = document.querySelector('html');
  let [isVisible, setIsvisible] = useState(false);
  let [isActive, setIsActive] = useState(false);
  let [indexItem, setIndexItem] = useState(0);
  let [coords,  setCoords] = useState(0);
  
  function handleClick() {

    if(!isVisible) {
      setCoords(coords = window.scrollY);
    }
    else {
      window.scrollTo({top: coords});
    }

    setTimeout(function() {
      setIsvisible(isVisible => !isVisible)
    },100)

  }

  useEffect(() => {

    const blocksObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach(item => {
          if (item.isIntersecting) {
            item.target.classList.add('start-anim');
          }
        })
      },
      {}
    );
    
    document
      .querySelectorAll('.animated-js')
      .forEach((block) => blocksObserver.observe(block))

  },[isVisible])

  function openModal(index: number) {
    html.classList.add('opened-modal');
    setIsActive(isActive = true);
    setIndexItem(indexItem = index);
  }

  function closeModal(event: any) {
    const target = event.target.classList; 
    if( target.contains('modal-window') || target.contains('modal-window__cross') ) {
      html.classList.remove('opened-modal');
      setIsActive(isActive = false);
    }
  }


  return(

    <section className="episode-section section">

      <div className="container">

        <TitleBase
          text="Latest episodes"
        />

        <div className="episode-section__items">
          {
            items.map( function(item: any, index: number) {
              if (isVisible && index > 3) return <EpisodeItem handleClick={ () => openModal(index) } classAdd={"animated-js"} key={item.id} {... item} />
              if (index >= 3) return;
              return <EpisodeItem handleClick={ () => openModal(index) } key={item.id} {... item} />
            })
          }
        </div>

        <button onClick={handleClick} className="episode-section__button button">
          {isVisible ? 'Hide' : 'Show More'}
        </button>

      </div>

      {isActive ? <ModalWindow handleClick={closeModal} title={items[indexItem].title} text={items[indexItem].text} addClass={'modal-window_active'}/> : null}

    </section>

  )
}