import { Routes, Route, Link, NavLink } from 'react-router-dom';
import HomePage from './pages/HomePage';
import EpisodePage from './pages/EpisodePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import NotfoundPage from './pages/NotfoundPage';
import StyleGuidePage from './pages/StyleGuidePage';
import InstructionPage from './pages/InstructionPage';
import ChangelogPage from './pages/ChangelogPage';
import CreditPage from './pages/CreditPage';
import PoweredWebflowPage from './pages/PoweredWebflowPage';
import LicensePage from './pages/LicensesPage';

import { useState } from 'react';
import ButtonMenu from './components/ButtonMenu/ButtonMenu';
import Logo from './components/Logo/Logo'; 
import Icon from './components/Icon/Icon';
import SocialItems from './components/SocialItems/SocialItems';


import './App.css';


function App() {

  let [active, setActive] = useState(false);

  function handleClick() {
    setActive(active => !active);
  }

  return (
    
      <>

        <header className={active ? 'header header_active' : 'header'}>
          <div className="container">

            <div className="header__row">

              <Logo />

              <ButtonMenu onClick={handleClick} active={active} addClass='header__button-menu' />

              <nav className='header__wrap-menu'>

                <ul className="header__menu">
                  <li>
                    <NavLink
                      to="/"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/EpisodePage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Episodes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/AboutPage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/ContactPage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Contact
                    </NavLink>
                  </li>
                </ul>

              </nav>

            </div>

          </div>
        </header>

        <main className="main">

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/EpisodePage" element={<EpisodePage />} />
            <Route path="/AboutPage" element={<AboutPage />} />
            <Route path="/ContactPage" element={<ContactPage />} />
            <Route path="/StyleGuidePage" element={<StyleGuidePage />} />
            <Route path="/InstructionPage" element={<InstructionPage />} />
            <Route path="/ChangelogPage" element={<ChangelogPage />} />
            <Route path="/CreditPage" element={<CreditPage />} />
            <Route path="/PoweredWebflowPage" element={<PoweredWebflowPage />} />
            <Route path="/LicensePage" element={<LicensePage />} />
            <Route path="*" element={<NotfoundPage/>} />
          </Routes>

        </main>


        <footer className="footer">
          <div className="container">

            <div className="footer__inner">

              <div className="footer__enum">

                <Logo />

                <div className="footer__social-items">
                  <a href="#">
                    <Icon className='icon-new' id="1" />
                  </a>
                  <a href="#">
                    <Icon className='icon-new' id="2" />
                  </a>
                  <a href="#">
                    <Icon className='icon-new' id="3" />
                  </a>
                </div>

              </div>

              <ul className="footer__menu">
                <li>
                    <NavLink
                      to="/"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/EpisodePage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Episodes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/Aboutpage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/ContactPage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Contact
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/StyleGuidePage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Style guide
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/InstructionPage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Instructions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/ChangelogPage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Changelog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/CreditPage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Credit
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/PoweredWebflowPage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Powered web flow
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/LicensePage"
                        className={({ isActive, isPending }) =>
                          isPending ? "pending" : isActive ? "active" : ""
                        }
                      >
                        Licenses
                    </NavLink>
                  </li>
              </ul>

              <SocialItems />

            </div>

          </div>
        </footer>

      </>

  );
}

export default App;